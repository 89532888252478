$(document).ready(function(){
    /* кроосбраузерный фиксированный фон при прокрутке */
    function Fixed_bg(fixedbg_block) {
        fixedbg_block.removeAttr("style");
        $(".body__inner").css("background", "transparent");
        $img = [];
        $height = []
        /* высчитывается высота блока с фикс фоном, записывается путь к картинке и соотв ему создаётся блок parallax-mirror*/
        for ($i = 0; $i < fixedbg_block.length; $i++) {
            $img[$i] = fixedbg_block.eq($i).data("image-src");
            fixedbg_block.eq($i).attr("data-block", $i);
            $height[$i] = fixedbg_block.eq($i).outerHeight();
            $("body").prepend("<div class='parallax-mirror' data-mirror='" + $i + "'></div>");
        }
        /* для созданных блоков задаётся нужная высота*/
        for ($i = 0; $i < fixedbg_block.length; $i++) {
            $p_h = $height[fixedbg_block.length - 1 - $i];
            $(".parallax-mirror").eq($i).css({
                "visibility": "hidden",
                "z-index": "-100",
                "position": "fixed",
                "top": "0",
                "left": "0",
                "overflow": "hidden",
                "width": "100vw",
                "height": $p_h
            });
            $(".parallax-mirror").eq($i).prepend("<div style='background-image: url(" + $img[fixedbg_block.length - 1 - $i] + ");'></div");
        }
        /* стили для картинок внутри зеркального блока*/
        $(".parallax-mirror").find("div").css({
            "position": "absolute",
            "left": "0",
            "top": "0",
            "height": "100vh",
            "width": "100%",
            "background-size": "cover",
            "background-position": "center",
            "background-repeat": "no-repeat"
        })
    }
    function Fixed_bg_position(d_t, fixed_bg) {
        for ($i = 0; $i < fixed_bg.length; $i++) {
            $b_t = fixed_bg.eq($i).offset().top;
            $a = $b_t - d_t;
            if (d_t >= $b_t - $(window).height() && d_t <= $b_t + fixed_bg.eq($i).outerHeight()) {
                $(".parallax-mirror").eq(fixed_bg.length - 1 - $i).css("visibility", "visible");
                $(".parallax-mirror").eq(fixed_bg.length - 1 - $i).css("transform", "translate3d(0," + $a + "px, 0)");
                $(".parallax-mirror").eq(fixed_bg.length - 1 - $i).find("div").css("transform", "translate3d(0," + (0 - $a) + "px, 0)");
            } else {
                $(".parallax-mirror").eq(fixed_bg.length - 1 - $i).css("visibility", "hidden");
            }
        }
    }
    function Fixed_bg_delete(fixed_bg) {
        $(".parallax-mirror").remove();
        fixed_bg.removeAttr("data-image-src");
        $(".body__inner").removeAttr("style");
    }
    if (typeof ($(".fixed-bg").attr("data-image-src")) !== undefined) {
        if ($(window).width() <= 575) {
            Fixed_bg_delete($(".fixed-bg"));
        } else {
            Fixed_bg($(".fixed-bg"));
            Fixed_bg_position($(document).scrollTop(), $(".fixed-bg"));
            $(window).scroll(function () {
                Fixed_bg_position($(document).scrollTop(), $(".fixed-bg"));
            });
            $(window).resize(function () {
                $(".parallax-mirror").remove();
                Fixed_bg($(".fixed-bg"));
                Fixed_bg_position($(document).scrollTop(), $(".fixed-bg"));
            });
        }
        $(window).resize(function () {
            if ($(window).width() <= 575) {
                Fixed_bg_delete($(".fixed-bg"));
            }
        })
    } else {
        $(".body__inner").removeAttr("style");
    }

    /* якорь наверх */
    $(".page-up").click(function() {
        $("body, html, .body__inner").animate({
            scrollTop: 0
        }, 500);
    })

    /* фиксированная шапка */
    $header_height = $(".header").outerHeight();
    $(window).scroll(function () {
        if ($(document).scrollTop() >= $header_height) {
            $(".header").addClass("header__fixed");
            $(".main-banner").css("margin-top", $header_height + "px");
        }
        else {
            $(".header").removeClass("header__fixed");
            $(".main-banner").removeAttr("style");
        }
    })

    /* мобильное меню */
    $(".header__menu-open").click(function() {
        $(".header__menu").slideDown();
    })
    $(".header__menu-close").click(function() {
        $(".header__menu").slideUp();
    })

    /* якоря в шапке */
    $(".header__menu a").click(function(e) {
        e.preventDefault();
        if ($(window).width() < 768) {
            $(".header__menu").slideUp();
        }
        $anchor = $(this).attr("href");
        $anchor_block = "section[data-anchor='" + $anchor + "']";
        if ($anchor == "contacts") {
            $top = $(document).outerHeight() - $(window).height();
        }
        else $top = $($anchor_block).offset().top;
        $(this).addClass("header__menu-active");
        $(".header__menu a").not($(this)).removeClass("header__menu-active");
        $("body, html").animate({
            scrollTop: $top - 50
        }, 1000);
    })

    /* маска номер телефона */
    $(".phone").mask("+7 999-999-99-99");

    /* animations */
    new WOW().init();
    $(window).scroll(function() {
        if ($(document).scrollTop() + $(window).height() > $(".characteristic").offset().top + 300) {
            $(".characteristic__title").addClass("characteristic__title-effect");
        }
    })
});